<template>
  <div
    :class="[
      'product-image',
      { 'product-image--outofstock': !inStock && !isPreSale },
    ]"
  >
    <img
      v-if="showSuddenPromo && false"
      src="~/assets/images/temporary-images/mimos_kit_pinceis.webp"
      alt="Ganhe um Kit de Pinceis"
      :class="['product__sudden-promo-img', 'product__sudden-promo-img--brush']"
    />

    <img
      v-if="showSuddenInfo"
      src="~/assets/images/temporary-images/mimos_marmita_etiqueta.webp"
      alt="Ganhe uma Marmita"
      :class="['product__sudden-promo-img']"
    />

    <img
      v-if="showSuddenGift"
      src="~/assets/images/temporary-images/tag-square-mimo.png"
      alt="Ganhe uma Square"
      :class="['product__sudden-promo-img']"
    />

    <img
      v-if="showSuddenBestPromo"
      src="~/assets/images/temporary-images/melhor_promo_etiqueta.webp"
      alt="Melhor Promo do Ano"
      :class="['product__sudden-promo-img', 'product__sudden-promo-img--best']"
    />

    <img
      v-if="productTag && ['all', 'listing'].includes(productTag.location)"
      :src="optimizedTagImageUrl"
      alt="Melhor Promo do Ano"
      :class="['product__sudden-promo-img', 'product__sudden-promo-img--best']"
    />

    <meta itemprop="image" :content="imageUrl" />
    <div
      v-if="forceShowLoading || loadingImage"
      :class="[
        'product-image__thumbnail',
        'product-image__thumbnail--loading',
        {
          'product-image__thumbnail--skip-gray': skipGrayBg,
        },
      ]"
    >
      <logo-loading color="#d7d7db" />
    </div>
    <img
      ref="image"
      v-srcset="{ srcsetType: type, isMobile: true }"
      v-lazyload
      :class="[
        'lazy',
        'product-image__thumbnail',
        'product-image__thumbnail--zero-opacity',
        {
          'product-image__thumbnail--loaded':
            !forceShowLoading && !loadingImage,
        },
      ]"
      :data-src="replacedImage || imageUrl"
      :title="image.alt"
      :alt="image.alt || 'Product Image'"
      :display_mode="image.display_mode"
      @mouseover="showGif"
      @mouseleave="showNormalImage"
      @load="onLoad"
      @error="onError"
    />
    <b
      v-if="showSuddenPromo && false"
      :class="[
        'product__sudden-promo-text',
        'product__sudden-promo-text--small',
      ]"
    >
      <img src="~/assets/images/bolt.png" alt="Despacho imediato icone" />
      DESPACHO IMEDIATO</b
    >
  </div>
</template>
<script>
import { PREVIEW_HOST } from '~/config'
import toQueryString from '~/utils/toQueryString'
import isPresent from '~/utils/isPresent'
import LogoLoading from '~/components/loadings/logo'

export default {
  name: 'ProductImage',
  components: {
    LogoLoading,
  },
  props: {
    changeDeviceImageSlug: {
      type: Array,
      default: null,
    },
    image: {
      type: Object,
      required: false,
      default: () => {},
    },
    reserveImage: {
      type: String,
      default: null,
    },
    imageInfo: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'catalogProduct',
    },
    inStock: {
      type: Boolean,
      default: true,
    },
    isPreSale: {
      type: Boolean,
      default: false,
    },
    skipGrayBg: {
      type: Boolean,
      default: false,
    },
    activeSuddenPromo: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
      default: null,
    },
    forceShowLoading: {
      type: Boolean,
      default: false,
    },
    gif: {
      type: String,
      default: null,
    },
    productTag: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      loadingImage: true,
      replacedImage: null,
    }
  },
  computed: {
    imageUrl() {
      let img =
        this.imageInfo?.src ||
        this.imageInfo?.url ||
        this.image?.src ||
        this.previewImage

      if (
        this.$store.state?.isUsStore &&
        img?.match('newecodeluxegocustomized')
      ) {
        img = img.replace('newecodeluxegocustomized', 'caseimpactslimstandard')
      }

      if (this.$store.state?.isUsStore && img?.match('standardprinteers')) {
        img = img.replace('standardprinteers', 'caseimpactslimstandard')
      }

      if (this.$store.state?.isUsStore && img?.match('iphone12nl')) {
        img = img.replace('iphone12nl', 'iphone12')
      }

      if (this.$store.state?.isUsStore && img?.match('freshbranca-garrafa')) {
        img = img.replace('freshbranca-garrafa', 'freshrosa-garrafa')
      }

      if (this.gif && this.$device.isMobile) {
        this.$nextTick(() => {
          this.isElementInMiddle()
        })
      }

      if (this.changeDeviceImageSlug?.[0] && this.changeDeviceImageSlug?.[1]) {
        img = img.replace(
          this.changeDeviceImageSlug[0],
          this.changeDeviceImageSlug[1]
        )
      }

      if (this.gif && this.gif.includes('.png')) {
        return this.gif
      }

      return img
    },
    optimizedTagImageUrl() {
      return `${this.productTag?.image}${'&tr=q-10,w-200'}`
    },
    showSuddenPromo() {
      return this.$isBrStore && this.material?.match('necessaire') && false
    },
    showSuddenInfo() {
      return (
        this.$isBrStore &&
        (this.material?.match('lancheira') || this.material?.match('lunch')) &&
        false
      )
    },
    showSuddenGift() {
      return (
        !!(
          this.$isBrStore &&
          (this.material?.match('mochilapop') ||
            this.material?.match('mochila-casual') ||
            this.sku?.match('mochila-casual') ||
            this.sku?.match('mochilapop'))
        ) && false
      )
    },
    showSuddenBestPromo() {
      return this.$isBrStore && false
    },
    material() {
      return this.imageInfo?.material
    },
    sku() {
      return this.imageInfo?.sku
    },
    darkMaterial() {
      // eslint-disable-next-line camelcase
      return this.imageInfo?.dark_material
    },
    customObject() {
      let customizationObject = {}
      if (isPresent(this.imageInfo?.customizations)) {
        customizationObject = this.imageInfo?.customizations.reduce(
          (customObj, customization) => {
            customObj[customization.code] = this.getCustomizationValue(
              customization
            )
            return customObj
          },
          {}
        )
      }
      return customizationObject
    },
    customParams() {
      return toQueryString(this.customObject, {
        removeBlankKeys: true,
      })
    },
    previewImage() {
      let url = null

      if (this.sku && this.material)
        url =
          encodeURI(`${PREVIEW_HOST}/${this.sku}/${this.material}/mockup?`) +
          this.customParams
      return url
    },
  },
  mounted() {
    if (this.gif && this.$device.isMobile) {
      document.addEventListener('scroll', this.isElementInMiddle)
    }
  },
  beforeDestroy() {
    if (this.gif && this.$device.isMobile) {
      document.removeEventListener('scroll', this.isElementInMiddle)
    }
  },

  methods: {
    isPresent,

    isElementInMiddle() {
      if (!this.$el) return
      const rect = this.$el.getBoundingClientRect()
      const windowHeight = window.innerHeight
      const centerY = windowHeight / 2
      if (rect.top <= centerY && rect.bottom >= centerY) {
        this.showGif()
      } else {
        this.showNormalImage()
      }
    },

    showGif() {
      if (this.gif) {
        if (this.replacedImage !== this.gif) {
          this.setThumbnailOpacity('0.1')
        }

        const image = new Image()
        image.src = this.gif
        image.onerror = () => {
          this.setThumbnailOpacity('1')
        }
        image.onload = () => {
          this.replacedImage = image.src
          this.setThumbnailOpacity('1')
        }
      }
    },

    showNormalImage() {
      this.replacedImage = null
      this.setThumbnailOpacity('1')
    },

    setThumbnailOpacity(opacity = 1) {
      this.$el.querySelector(
        '.product-image__thumbnail'
      ).style.opacity = opacity
    },

    getCustomizationValue(customization) {
      // In the future, this is where we can use user information to fill the values
      const formattedLocale = [this.$isBrStore ? 'pt_br' : 'en']
      if (this.darkMaterial) formattedLocale.push('dark')
      return customization.default_values[formattedLocale.join('_')] || ''
    },
    onLoad() {
      if (this.$refs.image) {
        const { src, srcset } = this.$refs.image
        if (src !== this.imagePlaceholder || srcset !== this.imagePlaceholder) {
          this.loadingImage = false
        }
      }
    },
    onError(e) {
      this.loadingImage = false
      if (this.reserveImage) this.image = this.reserveImage
    },
    onIntersect(_entries, _obs, isIntersecting) {
      if (isIntersecting) {
        this.loadingImage = true
      }
    },
  },
}
</script>

<style lang="scss">
.product__sudden-promo-img {
  width: 50px;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  &--best {
    width: 70px;
  }
}

.product__sudden-promo-text {
  position: absolute;
  z-index: 5;
  bottom: -4px;
  left: 0;
  width: 100%;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
  color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  img {
    width: 15px;
    margin-right: 1px;
  }
}

@media (min-width: $v4_desktop) {
  .product__sudden-promo-text {
    font-size: 18px;

    &--small {
      font-size: 11px;
    }
  }

  .product__sudden-promo-img--best {
    width: 65px;
  }
}

.product-image {
  padding-bottom: 100%;
  position: relative;

  &--outofstock {
    .product-image__thumbnail {
      filter: grayscale(80%);
    }
  }
  .product-image__thumbnail {
    position: absolute;
    width: 100%;
    display: block;
    transition: opacity 0.3s ease-in-out;

    &--zero-opacity {
      opacity: 0;
    }

    &--loaded {
      opacity: 1;
    }

    &--loading {
      z-index: 10;
      background: #f2f2f4;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 30px;
      }
    }

    &--skip-gray {
      background: transparent;
    }
  }
}

.lazy {
  position: relative;
  z-index: 1;
  opacity: 0;
  will-change: opacity;
  transition: all 0.3s;

  &--loaded {
    opacity: 1;
  }
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
</style>
