<template>
  <div
    :id="`product-${product.id}`"
    class="list-product"
    itemscope
    itemtype="https://schema.org/Product"
  >
    <div v-if="showNewBadge" class="list-product__new-tag">{{ $t('new') }}</div>
    <nuxt-link
      itemprop="url"
      class="product-gtm list-product__link catalog__item-link"
      :data-id="product.id"
      :data-name="product.name"
      :to="productLink"
      @click.native="onClick"
    >
      <!-- Image -->
      <div
        ref="productImages"
        class="product-images"
        @mouseover="stopAnimation"
        @mouseout="restartAnimation"
        @touchstart="stopAnimation"
        @touchend="restartAnimation"
      >
        <Icon360 v-if="isGifImage(product)" />
        <product-image
          v-for="img in animationImages"
          :key="img.src"
          ref="image"
          :product-tag="product.tag"
          :image="img"
          :gif="currentGif"
          :skip-gray-bg="!!colorOptions.length"
          :reserve-image="image.src"
          :change-device-image-slug="changeDeviceImageSlug"
          :image-info="imageInfo"
          :slug="product.slug"
          :type="type"
          :force-show-loading="isCartCrossell && !product.customization_url"
          :in-stock="isCartCrossell || inStock"
          :is-pre-sale="isPreSale"
          :product-id="product.id"
        />
      </div>
      <div v-if="isAnimated" class="product-images__dots">
        <div ref="dotItem" class="product-images__dots-item"></div>
      </div>

      <div
        v-if="product.badge && (isCartCrossell || inStock)"
        class="list-product__badge"
      >
        <badge :badge="product.badge" :catalog-has-badge="catalogHasBadge" />
      </div>

      <div
        v-if="!isCartCrossell && !inStock && !isPreSale"
        class="list-product__badge list-product__badge--outofstock"
      >
        <span class="list-product__badge-text">
          {{ $t('new_sold_off') }}
        </span>
      </div>

      <div
        v-if="colorOptions && colorOptions.length"
        class="list-product__colors"
      >
        <b v-if="type !== 'shelfProduct'" class="list-product__colors-title"
          >{{ $t('colors') }}:</b
        >
        <div class="list-product__colors-container">
          <span
            v-for="color in colorOptions"
            :key="color.color"
            class="list-product__color"
            :style="`border: 1px solid ${
              selectedColor &&
              selectedColor.case_device_id === color.case_device_id
                ? '#f37053'
                : '#d2d2d6'
            }; 
            ${
              type === 'shelfProduct'
                ? 'width:22px; height:22px;'
                : 'width:27px; height:27px;'
            }`"
            @click.prevent="selectColor(color)"
          >
            <span
              class="list-product__color-circle"
              :style="`background-color:#${color.color};`"
            >
            </span>
          </span>
          <span
            v-if="product.color_options.length > 4 && !showAllColors"
            class="list-product__color list-product__colors-more"
            :style="`${
              type === 'shelfProduct'
                ? 'width:22px; height:22px;'
                : 'width:27px; height:27px;'
            }`"
            @click.prevent="showAllColors = true"
            >+{{ product.color_options.length - 4 }}</span
          >
          <span
            v-else-if="product.color_options.length > 4 && showAllColors"
            class="list-product__color list-product__colors-more"
            :style="`${
              type === 'shelfProduct'
                ? 'width:22px; height:22px;'
                : 'width:27px; height:27px;'
            }`"
            @click.prevent="showAllColors = false"
          >
            -
          </span>
        </div>
      </div>

      <!-- Name -->
      <h3
        :class="[
          'list-product__name',
          {
            'list-product__name--colors': colorOptions.length,
          },
        ]"
        itemprop="name"
        :title="product.name"
      >
        {{ product.name }}
      </h3>
      <!-- Price and/or promotion -->
      <div v-if="loadingLocalePrices" class="loading-prices-container">
        <three-dots-loading />
      </div>
      <span
        v-if="displayPrice && !loadingLocalePrices"
        itemprop="offers"
        itemscope
        itemtype="https://schema.org/Offer"
      >
        <!-- Promotion Event-->
        <promotion-event
          v-if="
            showPromotionEvent &&
            isPresent(product.promotion) &&
            (isCartCrossell || inStock)
          "
          :old-price="promo.oldPrice"
          :price="promo.price"
          :quantity="promo.quantity"
          :sold="promo.sold"
          :discount="promo.discount"
          :end-date="promo.endDate"
        />
        <!-- Or Price/Old Price -->
        <span
          v-else-if="isCartCrossell || inStock || isPreSale"
          id="container__product-price"
          class="product-price"
          :class="{ 'product-price--sale': product.old_price }"
        >
          <span v-if="product.old_price" class="product-price__previous">{{
            oldPrice
          }}</span>
          <span class="product-price__current price selling">{{ price }}</span>
          <sold-off-tag v-if="showSoldOff" />
        </span>
        <span
          v-else-if="!inStock"
          id="container__product-price"
          class="product-price"
        >
          <span v-if="product.old_price" class="product-price__previous">{{
            oldPrice
          }}</span>
          <span class="product-price__current price selling">{{ price }}</span>
        </span>
        <span class="hidden" itemprop="price">{{ product.price }}</span>
        <span class="hidden" itemprop="priceCurrency">{{
          $store.state.currency
        }}</span>
      </span>

      <!--PreSale Button -->
      <transition v-if="showPreSaleModalBtn" name="fade">
        <a
          id="pre-sale-modal-button"
          class="product__pre-sale-btn"
          :class="{ 'product__pre-sale-btn--submitted': preSaleFormSubmitted }"
          rel="nofollow"
          @click.prevent="openPreSaleModal"
        >
          {{
            preSaleFormSubmitted
              ? $t('product.pre_sale.lead_subscribed')
              : $t('product.pre_sale.subscribe')
          }}
        </a>
      </transition>
    </nuxt-link>
    <pre-sale-modal
      v-if="showPreSaleModalBtn"
      :product-id="product.id"
      :material="currentMaterial"
      :case-device-id="currentCaseDeviceId"
      :is-modal-open="isPreSaleModalOpen"
      :type="type"
      @close-modal="closePreSaleModal"
      @form-submitted="changePreSaleFormSubmitted"
    />
    <div v-if="isCartCrossell" style="height: 16px">
      <nuxt-link
        v-if="product.slug_url && product.mockup_url && product.image"
        :to="`/${product.slug_url}/p/${
          product.case_device_id || ''
        }?customization_base=${product.customization_url}`"
        >{{ $t('change_customization') }}</nuxt-link
      >
      <small v-else style="font-size: 10px">{{
        $t('generating_customization')
      }}</small>
    </div>
    <button
      v-if="isCartCrossell"
      style="margin-top: 5px"
      type="button"
      :disabled="
        crossellProducts.includes(product.id) ||
        !product.mockup_url ||
        !product.image
      "
      :class="[
        'btn',
        'btn--small',
        'btn--block',
        {
          'btn--orange': !crossellProducts.includes(product.id),
          'btn--discrete': crossellProducts.includes(product.id),
        },
      ]"
      @click="addToCart(product)"
    >
      <div
        v-if="!product.mockup_url || !product.image"
        class="product__crossell"
      >
        <three-dots-loading />
      </div>
      <div v-else class="product__crossell">
        <span v-if="crossellProducts.includes(product.id)">
          {{ $t('product_form.added_to_cart') }}
        </span>
        <span v-else>
          <icon-bag /> {{ $t('product_form.add_to_cart_add') }}
        </span>
      </div>
    </button>
    <!-- GoForGood section -->
    <template v-if="product.goForGoodProduct">
      <div class="catalog__item-box">
        <strong>{{ product.institutionName }}.</strong>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="product.institutionDescription"></p>
      </div>
    </template>
  </div>
</template>

<script>
import Ga4Parser from '~/utils/Ga4Parser'
import pushDataLayer from '~/mixins/gtm/push-data-layer'
import priceable from '~/mixins/priceable'
import isPresent from '~/utils/isPresent'
import ProductImage from '~/components/product/ProductImage.vue'
import IconBag from '~/components/icons/bag/Bag'
import PromotionEvent from '~/components/product/PromotionEvent.vue'
import Icon360 from '~/components/icons/icon360/Icon360.vue'
import Badge from '~/components/product/Badge.vue'
import SoldOffTag from '~/components/product/SoldOffTag'
import toQueryString from '~/utils/toQueryString'
import ThreeDotsLoading from '~/components/loadings/three-dots/ThreeDotsLoading.vue'
import PreSaleModal from '~/components/product/PreSaleModal.vue'

const components = {
  ProductImage,
  PromotionEvent,
  Badge,
  SoldOffTag,
  ThreeDotsLoading,
  IconBag,
  Icon360,
  PreSaleModal,
}

export default {
  name: 'Product',
  components,
  mixins: [priceable, pushDataLayer],
  props: {
    /** Product - see ~/test/fixtures/product.json */
    product: {
      type: Object,
      default: () => ({}),
    },
    fixedPrice: {
      type: [Number, String],
      default: null,
    },
    captureClick: {
      type: Boolean,
      default: false,
    },
    catalogHasBadge: {
      type: Boolean,
      default: false,
    },
    showPromotionEvent: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      required: false,
      default: 'shelfProduct',
    },
    caseDeviceId: {
      type: [Number, String],
      default: null,
    },
    deviceSlug: {
      type: String,
      default: '',
    },
    sendGtmData: {
      type: Boolean,
      default: false,
    },
    showNewBadge: {
      type: Boolean,
      default: false,
    },
    showSoldOff: {
      type: Boolean,
      default: false,
    },
    isCartCrossell: {
      type: Boolean,
      default: false,
    },
    changeDeviceImageSlug: {
      type: Array,
      default: null,
    },
    showAllColors: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      animation: null,
      crossellProducts: [],
      crossellImage: null,
      selectedColor: {},
      isPreSaleModalOpen: false,
      preSaleFormSubmitted: false,
      loadingLocalePrices: false,
      optionsPrices: null,
      localPrice: null,
      localOldPrice: null,
      localPromotion: null,
    }
  },
  computed: {
    currentMaterial() {
      if (!this.selectedColor?.material) return
      return this.selectedColor?.material
    },
    currentCaseDeviceId() {
      // eslint-disable-next-line camelcase
      if (!this.selectedColor?.case_device_id) return
      // eslint-disable-next-line camelcase
      return this.selectedColor?.case_device_id || this.caseDeviceId
    },
    currentGif() {
      return this.selectedColor.gif_image || this.product.gif_image
    },
    inStock() {
      if (
        this.selectedColor?.color &&
        this.product &&
        // eslint-disable-next-line camelcase
        this.product?.color_options?.length !== 0
      ) {
        // eslint-disable-next-line camelcase
        return this.selectedColor?.in_stock
      }

      // eslint-disable-next-line camelcase
      return this.product?.in_stock
    },
    isPreSale() {
      // eslint-disable-next-line camelcase
      return this.product?.pre_sale_enabled
    },
    showPreSaleModalBtn() {
      return this.isPreSale && this.type !== 'shelfProduct'
    },
    colorOptions() {
      // eslint-disable-next-line camelcase
      let opts = this.product && this.product?.color_options

      if (this.showAllColors) {
      } else if (opts?.length) {
        opts = opts?.slice(0, 4)
      }

      return opts || []
    },
    // TODO: Adjust on endpoint adjustments
    promo() {
      const useLocal = this.localPrice !== null
      return {
        price: Number(useLocal ? this.localPrice : this.product.price),
        oldPrice: Number(
          useLocal
            ? this.localOldPrice
            : this.product.old_price || this.product.price
        ),
        quantity: Number(
          useLocal
            ? this.localPromotion
              ? this.localPromotion.quantity
              : null
            : this.product.promotion && this.product.promotion.quantity
        ),
        sold: Number(
          useLocal
            ? this.localPromotion
              ? this.localPromotion.sold
              : null
            : this.product.promotion && this.product.promotion.sold
        ),
        discount: Number(
          useLocal
            ? this.localPromotion
              ? this.localPromotion.discount
              : null
            : this.product.promotion && this.product.promotion.discount
        ),
        endDate: useLocal
          ? this.localPromotion
            ? this.localPromotion.end_date
            : null
          : this.product.promotion && this.product.promotion.end_date,
      }
    },
    animationImages() {
      return [
        this.image,
        ...(this.product.animation_images || []).map((img) => ({
          src: img,
          alt: this.product.name,
        })),
      ]
    },
    isAnimated() {
      return this.animationImages.length > 1
    },
    customParams() {
      let globalDevice = {}
      if (this.product.custom_case_device) {
        return `/${this.product.custom_case_device}`
      }

      if (this.product.product_type_code === 'case') {
        globalDevice = this.$store.state?.globalDevice
      }

      const caseDeviceId =
        // eslint-disable-next-line camelcase
        this.selectedColor?.case_device_id ||
        this.caseDeviceId ||
        // eslint-disable-next-line camelcase
        this.product.collection_preferences?.case_device_id ||
        // eslint-disable-next-line camelcase
        globalDevice?.case_device_id

      const deviceSlug =
        // eslint-disable-next-line camelcase
        this.product.collection_preferences?.device_code ||
        this.deviceSlug ||
        // eslint-disable-next-line camelcase
        globalDevice?.slug

      return `${caseDeviceId ? '/' + caseDeviceId : ''}${
        deviceSlug ? '-' + deviceSlug : ''
      }`
    },
    image() {
      return {
        src:
          this.selectedColor?.image ||
          this.crossellImage ||
          this.product?.image,
        alt: this.product.name,
      }
    },
    imageInfo() {
      // eslint-disable-next-line camelcase
      return { ...this.product?.image_info, src: this.image.src }
    },
    productToGtm() {
      return {
        id: this.product?.id,
        name: this.product?.name,
        sku: this.product?.sku,
        // eslint-disable-next-line camelcase
        category: this.product?.product_type_code,
        price: this.product?.price,
        productUrl: `${window.location.origin}/${this.product?.slug}/p${this.customParams}`,
      }
    },
    oldPrice() {
      const baseOldPrice =
        this.localPrice !== null ? this.localOldPrice : this.product.old_price
      return this.formatPrice(baseOldPrice)
    },
    price() {
      const basePrice =
        this.localPrice !== null
          ? this.localPrice
          : this.fixedPrice || this.product.price
      return this.formatPrice(basePrice)
    },
    displayPrice() {
      return (
        isPresent(this.product?.price) &&
        !['0', '0.0', 0].includes(this.product?.price)
      )
    },
    productLink() {
      if (this.isCartCrossell || this.captureClick) return '#'

      // eslint-disable-next-line camelcase
      return this.product?.is_kit
        ? `/kits/${this.product.slug}`
        : `/${this.product.slug}/p${this.customParams}?${this.queryString(
            this.product
          )}`
    },
  },
  watch: {
    product: {
      deep: true,
      handler(prod) {
        if (prod.image) {
          this.crossellImage = prod.image
          this.setColor()
        }
      },
    },
  },
  mounted() {
    if (this.isAnimated) this.startAnimation()
    this.setColor()
  },
  beforeDestroy() {
    this.stopAnimation()
  },
  methods: {
    openPreSaleModal() {
      this.isPreSaleModalOpen = true
    },
    closePreSaleModal() {
      this.isPreSaleModalOpen = false
    },
    changePreSaleFormSubmitted() {
      this.preSaleFormSubmitted = true
    },
    isGifImage(product) {
      return product.gif_image
    },
    isPresent,
    async selectColor(color) {
      this.selectedColor = color

      if (!this.optionsPrices) {
        const caseDeviceIds = this.product.color_options
          .map((item) => item.case_device_id)
          .join(',')

        try {
          this.loadingLocalePrices = true
          this.optionsPrices = await this.$axios.$get('/products/price', {
            params: {
              ...this.$store.getters.storeInfo,
              variant_id: this.product.variant_id,
              case_device_ids: caseDeviceIds,
              product_id: this.product.id,
            },
          })
        } catch (error) {
        } finally {
          this.loadingLocalePrices = false
        }
      }

      if (this.optionsPrices) {
        const caseDevicePrice = this.optionsPrices.find(
          (item) => item.case_device_id === color.case_device_id
        )
        if (caseDevicePrice) {
          this.localPrice = caseDevicePrice.price
          this.localOldPrice = caseDevicePrice.old_price
          this.localPromotion = caseDevicePrice.promotion
        }
      }
    },
    setColor() {
      // eslint-disable-next-line camelcase
      const colorOptions = this.product?.color_options

      if (colorOptions?.length) {
        this.selectedColor = this.findColor(colorOptions) || colorOptions[0]
      }
    },
    findColor(colorOptions) {
      return (
        this.findColorByCaseDeviceId(colorOptions) ||
        this.findColorByShowcaseDefault(colorOptions) ||
        this.findColorInStock(colorOptions)
      )
    },
    findColorByShowcaseDefault(colorOptions) {
      return colorOptions.find(
        // eslint-disable-next-line camelcase
        (color) => color?.is_showcase_default && color?.in_stock
      )
    },
    findColorByCaseDeviceId(colorOptions) {
      return colorOptions.find(
        // eslint-disable-next-line camelcase
        (color) => color?.case_device_id === this.caseDeviceId
      )
    },
    findColorInStock(colorOptions) {
      // eslint-disable-next-line camelcase
      return colorOptions.find((color) => color?.in_stock)
    },
    async addToCart(product) {
      try {
        this.$displayOverlaidLoading()
        await this.$axios.$post('/carts/populate', {
          variant_id: product.variant_id,
          quantity: 1,
          customizations: {
            [product.customization_id]: product.customization_url,
          },
          number: this.$store.state.orderNumber,
          image_url: product.image,
          engine_information: {
            img: product.customization_url,
          },
          device_id: product.device_id,
          case_device_id: product.case_device_id,
          added_by_suggestion: true,
          ...this.$store.getters.storeInfo,
        })
        this.crossellProducts.push(product.id)

        const prod = {
          ...JSON.parse(JSON.stringify(this.product)),
          product_id: this.product.id,
          price: this.currentPrice,
          promotion: this.currentPromotion,
          case_device_id: this.currentCaseDeviceId,
        }

        const ga4Items = new Ga4Parser(this.$store.state).addToCart(prod)

        /* 
          Prevent Side Effect since ga4EventValue is an array, 
          gtm automaticly append a new item instead of create a new array  
        */
        this.pushDataLayer({
          ga4EventValue: undefined,
        })

        this.pushDataLayer({
          event: 'genericEvent',
          eventCategory: 'interactWithPage',
          eventAction: 'clickOnButton',
          eventLabel: 'addToCartFromCrossell',
          eventValue: {
            currency: this.$store.state.currency,
            product: {
              id: product.id,
              name: product.name,
              price: product.price,
              sku: product.sku,
              has_customization: true,
              variant_id: product.variant_id,
              raw_name: product.name,
            },
          },
          ga4EventValue: ga4Items,
        })
      } catch (err) {
        this.$displayTopMessage(this.$t('action_error'), {
          type: 'error',
        })
      } finally {
        this.$displayOverlaidLoading('close')
      }
    },
    queryString(pd) {
      const obj = {
        'out-of-stock': !this.isCartCrossell && !pd.in_stock ? 1 : '',
      }

      return toQueryString(obj, { removeBlankKeys: true })
    },
    restartAnimation() {
      if (this.isAnimated) {
        this.stopAnimation()
        this.restartedAnimation = setTimeout(() => {
          this.startAnimation()
        }, 3000)
      }
    },
    stopAnimation() {
      if (this.isAnimated) {
        if (this.animation) clearInterval(this.animation)
        if (this.restartedAnimation) clearTimeout(this.restartedAnimation)
        document.removeEventListener('scroll', this.startScrollObserver)
      }
    },
    startAnimation() {
      const productImages = this.$refs.productImages
      const arrayImages = productImages.children
      const dot = this.$refs.dotItem
      let containerWidth, dotWidth

      let index = 0

      this.animation = setInterval(() => {
        if (index > arrayImages.length - 1) index = 0
        if (!containerWidth) containerWidth = arrayImages[0].clientWidth
        if (!dotWidth) {
          dotWidth = containerWidth / arrayImages.length
          dot.style.width = dotWidth + 'px'
        }
        productImages.scrollLeft = containerWidth * index
        // dot.style.transform = `translateX(${dotWidth * index}px)`
        index++
      }, 2000)
      this.startScrollObserver(productImages, dot)
    },
    startScrollObserver(productImages, dot) {
      productImages.addEventListener('scroll', function (e) {
        dot.style.transform = `translateX(${
          e.target.scrollLeft / productImages.children.length
        }px)`
      })
    },
    onClick(e) {
      if (this.isCartCrossell) e.preventDefault()
      this.sendToGtm()
      this.$emit('click', this.product)
    },
    sendToGtm() {
      this.$store.commit('CURRENT_CLICKED_PRODUCT_ID', this.product.id)
      if (this.type === 'shelfProduct')
        this.pushDataLayer({
          event: 'genericEvent',
          eventCategory: 'interactWithPage',
          eventAction: 'clickOnLink',
          eventLabel: 'clickOnShelfProduct',
          eventValue: this.productToGtm,
        })

      if (this.sendGtmData)
        this.pushDataLayer({
          event: 'genericEvent',
          eventCategory: 'interactWithPage',
          eventAction: 'clickOnLink',
          eventLabel: 'clickOnProduct',
          eventValue: this.productToGtm,
        })
    },
  },
}
</script>

<style lang="scss">
.list-product__colors-more {
  border: 1px solid #818181;
  display: flex;
  font-size: 11px;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}

.product__pre-sale-btn {
  color: #fff;
  background-color: #444;
  padding: 6px 3px 6px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 700;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 5px;
  cursor: pointer;

  &--submitted {
    background-color: $color_cart_btn;
  }
}

.product__crossell {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
  }

  svg {
    fill: white;
    height: 20px;
    margin-right: 10px;
  }
}

.list-product {
  overflow: hidden;
  font-size: 13px;
  position: relative;
  text-align: center;

  &__colors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 3px;

    &-title {
      font-size: 11px;
    }

    &-container {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
    }
  }

  &__color {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    transition: border 0.3s ease;
    border-radius: 50%;

    &-circle {
      border: 0.5px solid rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: inline-block;
    }
  }

  &.list-product--boxed {
    box-shadow: inset 0 0 0 1px $v4_color_primary_light;
    padding: 15px;
    border-radius: 12px;
    background-color: white;
    margin-bottom: 1px;

    &:hover {
      box-shadow: inset 0 0 0 1px darken($v4_color_primary_light, 5%);
    }

    @media (min-width: $v4_desktop) {
      padding: 20px;

      .list-product__name {
        margin-top: 25px;
      }
    }
  }

  .product-price__previous {
    color: #555;
    text-decoration: line-through;
  }

  .list-product__new-tag {
    position: absolute;
    top: 12px;
    right: -28px;
    width: 100px;
    background: $v4_color_primary;
    text-transform: uppercase;
    font-size: 11px;
    text-align: center;
    color: white;
    padding: 2px 0 1px;
    transform: rotate(45deg);
    z-index: 2;
  }

  .list-product__link {
    text-decoration: none;
    color: inherit;
    display: block;
    position: relative;
  }

  .list-product__name {
    color: $v4_color_text;
    margin: $v4_gutter 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    max-width: 100%;
    font-size: inherit;
    font-weight: normal;
    margin-top: 25px;

    &--colors {
      margin-top: 0px !important;
    }
  }

  .list-product__badge {
    position: absolute;
    z-index: 2;
    margin-top: -17px;
    left: 0;
    right: 0;

    &--outofstock {
      .list-product__badge-text {
        color: #fff;
        background-color: #c2c2c6;
        padding: 2px 3px 3px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: bold;
        margin-top: -8px;
        display: inline-block;
        vertical-align: top;
        width: 100%;

        @media (max-width: 550px) {
          margin-top: -6px;
          font-size: 10px;
        }
      }
    }

    img {
      height: 17px;
      display: block;
      margin: 0 auto;
    }
  }
}

.product-images {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow: auto;
  scroll-behavior: smooth;
  transition: scroll 0.5s ease;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
  }

  overflow: -moz-scrollbars-none;

  .product-image {
    flex-shrink: 0;
    scroll-snap-align: start;
    width: 100%;
  }
}

.product-images__dots {
  display: flex;
  width: 100%;

  .product-images__dots-item {
    height: 3px;
    width: 30px;
    background: $v4_color_lightblue;
    // transition: transform 0.5s ease;
    border-radius: 5px;
  }
}

.icon-360 {
  display: flex;
  width: 50px;
  height: 40px;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 2;
}

.icon-line-360 {
  transform: translate3d(0, 0, 0);
  animation-name: shakeMe;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes shakeMe {
  2%,
  18% {
    transform: translate3d(-5px, 0, 0);
  }

  4%,
  16% {
    transform: translate3d(5px, 0, 0);
  }

  6%,
  10%,
  14% {
    transform: translate3d(-5px, 0, 0);
  }

  8%,
  12% {
    transform: translate3d(5px, 0, 0);
  }

  18.1% {
    transform: translate3d(0px, 0, 0);
  }
}

.loading-prices-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 5px;
}
</style>
